@font-face {
  font-family: 'Bandeins Strange Variable';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/BandeinsSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Code Pro';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/SourceCodePro-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'IBMPlexMono';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/JetBrainsMono-Regular.ttf);
}


@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/Poppins-Regular.ttf);
}

@supports (font-variation-settings: normal) {
  * {
    font-family: 'Poppins';
    font-weight: 500;
  }
}
