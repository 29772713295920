.cursor-pointer {
  cursor: pointer;
}

.font-medium {
  font-weight: 500;
}

.divider {
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
  width: 100%;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.font-ibm {
  font-family: 'IBMPlexMono', sans-serif !important;
}
